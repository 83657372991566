import { liteClient } from 'algoliasearch/lite';
import { history } from 'instantsearch.js/es/lib/routers';
import config from './Config';

const searchClient = liteClient(config.algoliaAppId, config.algoliaSearchKey);

const routing = {
  router: history({
    cleanUrlOnDispose: false,
    parseURL({ qsModule, location }) {
      const {
        query = '',
        page,
        discountPercentage,
        isOutlet,
        extraDiscount,
        brand,
        color,
        size,
        finalPrice,
        ordering,
        search,
        shops,
      } = qsModule.parse(location.search.slice(1));

      let indexName = config.algoliaSearchIndex;

      const categories = location.pathname
        .split('/')
        .filter((slug) => !['tooted', 'ru', 'lv', 'et', ''].includes(slug));

      return {
        query: decodeURIComponent(query.toString()),
        page,
        discountPercentage,
        isOutlet,
        extraDiscount,
        brand,
        color,
        size,
        finalPrice,
        indexName,
        categories,
        search,
        ordering,
        shops,
      };
    },
    createURL({ qsModule, location, routeState }) {
      const { origin, pathname, hash, search } = location;

      const queryStrItems = { ...routeState };
      let categoriesStr = '';
      if (queryStrItems['categoriesSlug.lvl0']) {
        categoriesStr = routeState['categoriesSlug.lvl0'];
      }
      if (
        Object.prototype.hasOwnProperty.call(
          queryStrItems,
          'categoriesSlug.lvl0',
        )
      ) {
        delete queryStrItems['categoriesSlug.lvl0'];
      }

      // Preserve UTM parameters for analytics tracking
      const existingQueryParameters = qsModule.parse(search.slice(1)) || {};
      Object.keys(existingQueryParameters)
        .filter((key) => key.startsWith('utm_'))
        .forEach((key) => {
          queryStrItems[key] = existingQueryParameters[key];
        });

      const queryString = qsModule.stringify(queryStrItems);
      const originalPath = pathname
        .split('/')
        .filter((slug) => ['tooted', 'lv', 'et'].includes(slug))
        .join('/');

      let newPath = `/${originalPath}`;
      if (categoriesStr) {
        newPath += `/${categoriesStr.replaceAll(',', '/')}`;
      }

      let finalUrl = `${origin}${newPath}`;
      if (queryString) {
        finalUrl += `?${queryString}`;
      }
      if (hash) {
        finalUrl += hash;
      }

      if (!queryString && !categoriesStr) {
        return;
      }

      return finalUrl;
    },
  }),
  stateMapping: {
    stateToRoute(uiState) {
      const routeState = {};
      Object.keys(uiState).forEach((indexName) => {
        const indexState = uiState[indexName];
        Object.keys(indexState).forEach((facetType) => {
          if (facetType === 'sortBy' && indexState[facetType]) {
            if (indexState[facetType].includes('_price_asc')) {
              routeState['ordering'] = 'PRICE_ASC';
            }
            if (indexState[facetType].includes('_price_desc')) {
              routeState['ordering'] = 'PRICE_DESC';
            }
            if (indexState[facetType].includes('_date_desc')) {
              routeState['ordering'] = 'NEW_ARRIVAL_DESC';
            }
          }

          if (typeof indexState[facetType] === 'object') {
            const facets = indexState[facetType];
            Object.keys(facets).forEach((facetName) => {
              if (
                [
                  'brand',
                  'color',
                  'size',
                  'isOutlet',
                  'shops',
                  'categoriesSlug.lvl0',
                ].includes(facetName)
              ) {
                routeState[facetName] = facets[facetName]?.join(',');
              }
              if (['discountPercentage'].includes(facetName)) {
                routeState[facetName] = facets[facetName];
              }

              if (['finalPrice'].includes(facetName)) {
                routeState[facetName] = facets[facetName];
              }
            });
          }
        });

        if (indexState.page) {
          routeState['page'] = indexState.page;
        }

        if (indexState.query) {
          routeState['query'] = indexState.query;
        }
      });

      return routeState;
    },
    routeToState(routeState) {
      const uiState = {
        hierarchicalMenu: {
          'categoriesSlug.lvl0': undefined,
        },
        numericMenu: { discountPercentage: undefined },
        refinementList: {
          isOutlet: undefined,
          color: undefined,
          brand: undefined,
          size: undefined,
          shops: undefined,
        },
        range: {
          finalPrice: undefined,
        },
        sortBy: undefined,
      };

      if (routeState.discountPercentage) {
        uiState.numericMenu.discountPercentage = routeState.discountPercentage;
      }

      if (routeState.isOutlet) {
        uiState.refinementList.isOutlet = [routeState.isOutlet];
      }

      if (routeState.extraDiscount) {
        uiState.refinementList.isOutlet = [routeState.extraDiscount];
      }

      if (routeState.color) {
        uiState.refinementList.color = routeState.color.split(',');
      }

      if (routeState.brand) {
        uiState.refinementList.brand = routeState.brand.split(',');
      }

      if (routeState.shops) {
        uiState.refinementList.shops = routeState.shops.split(',');
      }

      if (routeState.size) {
        uiState.refinementList.size = routeState.size.split(',');
      }

      if (routeState.finalPrice) {
        uiState.range.finalPrice = routeState.finalPrice;
      }

      if (routeState.categories) {
        uiState.hierarchicalMenu['categoriesSlug.lvl0'] = routeState.categories;
      }

      if (routeState.query) {
        uiState['query'] = routeState.query;
      }

      if (routeState.page) {
        uiState['page'] = routeState.page;
      }

      if (routeState.ordering) {
        if (routeState.ordering === 'PRICE_ASC') {
          uiState['sortBy'] = `${routeState.indexName}_price_asc`;
        }
        if (routeState.ordering === 'PRICE_DESC') {
          uiState['sortBy'] = `${routeState.indexName}_price_desc`;
        }
        if (routeState.ordering === 'NEW_ARRIVAL_DESC') {
          uiState['sortBy'] = `${routeState.indexName}_date_desc`;
        }
      }

      return { [routeState.indexName]: uiState };
    },
  },
};

export { searchClient, routing };
